import React from 'react';
import moment from 'moment';

function Rating({ rating }) {
  const stars = [];
  for (let i = 5; i > 0; i -= 1) {
    if (rating >= i) {
      stars.push(<div className="active" key={i} />);
    } else {
      stars.push(<div key={i} />);
    }
  }
  return (
    <div itemProp="reviewRating" className="review-rating star-rating">
      {
        [1, 2, 3, 4, 5].reverse().map((star) => <div className={(rating >= star) ? 'active' : ''} key={star} />)
}
    </div>
  );
}

function Review({ review, defaultAvatar }) {
  return (
    <li itemProp="review">
      <div className="review-header">
        <img className="review-avatar avatar-40" role="presentation" src={review.user.avatar_url ? review.user.avatar.thumb.small : defaultAvatar} alt={review.user.full_name} />

        <div className="review-header-info">
          <p className="body-heavy">{review.user.full_name}</p>
          <p itemProp="dateCreated" className="subhead text-light">{moment(review.created_at).format('LL')}</p>
        </div>

        <Rating rating={review.rating} />
      </div>

      <div className="review-text">
        <p className="subhead" itemProp="reviewBody">{review.description}</p>
      </div>
    </li>
  );
}

function ReviewsList({ reviews, defaultAvatar }) {
  return reviews.length > 0
    ? (
      <ol className="reviews">
        {reviews.map((rev) => <Review review={rev} key={rev.user.id} defaultAvatar={defaultAvatar} />)}
      </ol>
    )
    : <p className="body-regular-light">Reviews not found for this course.</p>;
}

export default class ReviewsExpanded extends React.Component {
  constructor(props) {
    super(props);
    this.closeExpandedView = this.closeExpandedView.bind(this);
    this.defaultAvatar = this.props.defaultAvatar;
  }

  closeExpandedView() {
    this.props.unmount();
  }

  render() {
    return (
      <div className="course-section-expanded reviews-expanded">
        <div className="course-section-expanded-header">
          <div className="ho-container">
            <div className="header-info">
              <h4 className="title-5">Reviews</h4>
              <h3 className="title-4">{this.props.course.name}</h3>
            </div>

            <div className="header-actions">
              <span className="ic-close-gray" onClick={this.closeExpandedView} />
            </div>
          </div>
        </div>

        <div className="ho-container-small">
          <ReviewsList reviews={this.props.reviews} defaultAvatar={this.defaultAvatar} />
        </div>
      </div>
    );
  }
}
