import React from 'react';

export default class Team extends React.Component {
  render() {
    const ceo = {
      name: 'Anthony Douglas',
      title: 'CEO',
      imageClass: 'anthony',
    };

    const teamMembers = [
      {
        name: 'André Andrade',
        title: 'Software Engineer',
        imageClass: 'andre',
      },
      {
        name: 'João Costa',
        title: 'Software Engineer',
        imageClass: 'joao',
      },
      {
        name: 'Lénia Duarte',
        title: 'Customer Service',
        imageClass: 'leniaD',
      },
      {
        name: 'Pedro Lança',
        title: 'Head of Design',
        imageClass: 'pedro',
      },
      {
        name: 'Ricardo Santana',
        title: 'Customer Service',
        imageClass: 'ricardoS',
      },
      {
        name: 'Vera Calvário',
        title: 'Head of Customer Service',
        imageClass: 'veraC',
      },
      {
        name: 'Vera Reis',
        title: 'Customer Service',
        imageClass: 'veraR',
      },
      {
        name: 'Pedro Vinhas',
        title: 'Software Engineer',
        imageClass: 'pedroVinhas',
      },
      {
        name: 'Francisco Silva',
        title: 'Head of Finance',
        imageClass: 'franciscoSilva',
      },
      {
        name: 'Pedro Glória',
        title: 'Software Engineer',
        imageClass: 'pedroGloria',
      },
      {
        name: 'Max Harvey',
        title: 'Head of Partnerships',
        imageClass: 'max',
      },
      {
        name: 'Mafalda Gil',
        title: 'Customer Service',
        imageClass: 'mafalda',
      },
      {
        name: 'Jorge Robalo',
        title: 'Customer Service',
        imageClass: 'jorge',
      },
      {
        name: 'Catarina Mendes',
        title: 'Head of People',
        imageClass: 'catarina',
      },
      {
        name: 'Nuno Grazina',
        title: 'Product Manager',
        imageClass: 'nuno',
      },
      {
        name: 'Raquel Melo',
        title: 'Designer',
        imageClass: 'raquel',
      },
      {
        name: 'Gonçalo Silva',
        title: 'Software Engineer',
        imageClass: 'goncalo',
      },
      {
        name: 'Ben Hacker',
        title: 'Head of Marketing',
        imageClass: 'ben',
      },
      {
        name: 'Catarina Monteiro',
        title: 'Software Engineer',
        imageClass: 'catarinaMonteiro',
      },
      {
        name: 'Afonso Bento',
        title: 'CORE Golf Business & Operations Manager',
        imageClass: 'afonso',
      },
      {
        name: 'Rui Vieira',
        title: 'Software Engineer',
        imageClass: 'rui',
      },
      {
        name: 'Margarida Maia',
        title: 'Quality Assurance Analyst',
        imageClass: 'margarida',
      },
      {
        name: 'Maria Fatério',
        title: 'Mapping Specialist',
        imageClass: 'maria',
      },
      {
        name: 'Artur Cunha',
        title: 'Mapping Specialist',
        imageClass: 'artur',
      },
      {
        name: 'Chris Somerton',
        title: 'Head of E-Commerce',
        imageClass: 'chris',
      },
      {
        name: 'Brunna Tolentino',
        title: 'Office Manager',
        imageClass: 'brunna',
      },
      {
        name: 'Nuno Cruz',
        title: 'Software Engineer',
        imageClass: 'nunoCruz',
      },
      {
        name: 'David Silva',
        title: 'Software Engineer',
        imageClass: 'davidSilva',
      },
      {
        name: 'João Oliveira',
        title: 'Software Engineer',
        imageClass: 'joaoOliveira',
      },
      {
        name: 'Arthur Almeida',
        title: 'Site Reliability Engineer',
        imageClass: 'arthurAlmeida',
      },
      {
        name: 'João Borges',
        title: 'Software Engineer',
        imageClass: 'joaoBorges',
      },
      {
        name: 'Thiago Lannes',
        title: 'Quality Assurance Analyst',
        imageClass: 'thiago',
      },
      {
        name: 'Francisco Farinha',
        title: 'Software Engineer',
        imageClass: 'franciscoFarinha',
      },
      {
        name: 'Serhii Ihnatiev',
        title: 'Software Engineer',
        imageClass: 'serhii',
      },
      {
        name: 'Gabriel Bellon',
        title: 'Software Engineer',
        imageClass: 'gabriel',
      },
    ];

    const team = () => {
      let i;
      const t = [ceo];
      const restOfTeam = teamMembers.slice();

      while (restOfTeam.length > 0) {
        i = Math.floor(Math.random() * restOfTeam.length);
        t.push(restOfTeam[i]);
        restOfTeam.splice(i, 1);
      }
      return t;
    };

    return (
      <div>
        <ul>
          {team().map((member) => (
            <li key={member.name}>
              <figure>
                <img id={member.imageClass} alt={member.name} className="carrer-profile" />
                <figcaption>
                  <h5 className="subhead">{member.name}</h5>
                  <p className="subhead">{member.title}</p>
                </figcaption>
              </figure>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
