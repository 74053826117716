import React from 'react';
import StoreButtons from './StoreButtons.es6';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isTopBarVisible: false };
    this.images = props.images;
    this.universalAppStoreUrl = props.universal_app_store_url;
  }

  componentDidMount() {
    const scrollTarget = document.querySelector('.reviews').offsetTop;

    window.addEventListener('scroll', () => {
      if (window.scrollY >= (scrollTarget + 100)) {
        this.setState({ isTopBarVisible: true });
      } else {
        this.setState({ isTopBarVisible: false });
      }
    });
  }

  render() {
    const { images } = this;
    const topBarClass = this.state.isTopBarVisible ? 'visible' : '';

    return (
      <div>
        <div className={`top-bar ${topBarClass}`}>
          <div className="container flex-repel">
            <a href="/" className="logo">Hole19 Golf</a>

            <div className="top-bar-cta">
              <div className="badge-aotd" />
              <StoreButtons />
              <a href={this.universalAppStoreUrl} className="cta-mobile button-small bg-primary-2">Download Hole19</a>
            </div>
          </div>
        </div>

        <section className="hero">
          <div className="container-10 container-2-columns">
            <div className="hero-content">
              <div className="content">
                <h1 className="hero-title title-1 text-primary-2">Know More. Score Less.</h1>
                <p className="hero-subtitle title-5">The most complete mobile app for golfers.</p>

                <div className="hero-cta">
                  <StoreButtons />

                  <div className="stats">
                    <div className="badge-aotd" />

                    <div className="stat">
                      <p className="value headline-bold">3.9M+</p>
                      <p className="description caption-2">Users worldwide</p>
                    </div>

                    <div className="stat">
                      <p className="value headline-bold">57M+</p>
                      <p className="description caption-2">Rounds played</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-device">
              <img src={images.heroDevice} alt="Hole19 app in iPhone and Apple Watch" />
            </div>
          </div>
        </section>

        <section className="reviews">
          <div className="container-10">
            <h4 className="section-title title-6">What our golfers are saying</h4>
          </div>

          <div className="premium-reviews-slider">
            <div className="reviews-wrapper"><ul className="reviews-container" /></div>
          </div>
        </section>

        <section className="main-features">
          <ul className="features-list">
            <li className="feature yardages">
              <div className="container grid-2">
                <div className="feature-devices flex-col-center">
                  <img className="device-large" src={images.deviceDistances} alt="Yardages" />
                  <img className="device-small" src={images.deviceFlyover} alt="Flyover" />
                </div>

                <div className="feature-text">
                  <h2 className="feature-title title-3">Get reliable yardages, make confident decisions</h2>
                  <p className="body-medium">Use Hole19 to get precise GPS distances to key hazards or a complete overview of the hole. Make better decisions during your round, with no need for an expensive rangefinder, by downloading the Hole19 golf app for free.</p>
                </div>
              </div>
            </li>

            <li className="feature input inverted">
              <div className="container grid-2">
                <div className="feature-devices">
                  <img className="device-large" src={images.deviceInput} alt="Simple Input" />
                  <img className="device-watch" src={images.deviceWatchInput} alt="Watch Input" />
                </div>

                <div className="feature-text">
                  <h2 className="feature-title title-3">Track your scores</h2>
                  <p className="body-medium">Paper and pencil have had their day. With Hole19, you can quickly and easily keep scores for yourself and your buddies.</p>
                </div>
              </div>
            </li>

            <li className="feature performance">
              <div className="container grid-2">
                <div className="feature-devices flex-col-center">
                  <img className="device-large" src={images.devicePerformance} alt="Performance" />
                  <img className="device-small" src={images.deviceAndroidPerformance} alt="Performance Graphics" />
                </div>

                <div className="feature-text">
                  <h2 className="feature-title title-3">Performance Insights</h2>
                  <p className="body-medium">With on-course stats, club-by-club distances and even trend analysis, Hole19 can reveal meaningful insights into your game, helping you better understand areas of weakness and tailor your future practice.</p>
                </div>
              </div>
            </li>

            <li className="feature leaderboards inverted">
              <div className="container grid-2">
                <div className="feature-devices flex-col-center">
                  <img className="device-small" src={images.deviceLeaderboard} alt="Single Leaderboard" />
                  <img className="device-large" src={images.deviceLeaderboards} alt="Live Leaderboards" />
                </div>

                <div className="feature-text">
                  <h2 className="feature-title title-3">Live Leaderboards</h2>
                  <p className="body-medium">Golfers are inherently competitive, and that's why we introduced Live Leaderboards - to allow you to compete against your buddies more easily. You can also share the leaderboard with spectators and pray you don't finish last.</p>
                </div>
              </div>
            </li>

            <li className="feature wear">
              <div className="container grid-2">
                <div className="feature-devices flex-col-center">
                  <img className="device-watch" src={images.deviceWatchDistances2} alt="Performance" />
                  <img className="device-watch-android" src={images.deviceWatchAndroidInput} alt="Advanced Stats" />
                </div>

                <div className="feature-text">
                  <h2 className="feature-title title-3">Your game on your wrist</h2>
                  <p className="body-medium">You might not want to use your phone on the course; the good news is you don't have to. Hole19 provides GPS yardages and scoring input right on your smartwatch.</p>
                  <a href="/wear" className="button-1 disclosure text-primary-2">Companion</a>
                </div>
              </div>
            </li>
          </ul>
        </section>

        <section className="premium-card">
          <div className="container">
            <div className="card">
              <div className="card-content flex-repel">
                <div className="card-info-wrapper">
                  <h3 className="card-title title-2">Upgrade Your Game</h3>
                  <p className="card-description body-medium">Unlock the full range of game enhancing features available via Hole19 Premium and get ready to level-up your golf game even faster.</p>

                  <ul className="features-list grid-2">
                    <li><p className="subhead-bold">Shot Tracker</p></li>
                    <li><p className="subhead-bold">HCP Calculator</p></li>
                    <li><p className="subhead-bold">Match Play</p></li>
                    <li><p className="subhead-bold">Watch Scoring</p></li>
                    <li><p className="subhead-bold">Club Recommendation</p></li>
                    <li><p className="subhead-bold">Plus much more</p></li>
                  </ul>
                </div>

                <div className="card-cta-wrapper">
                  <a href="/premium" className="card-cta button-regular bg-primary-2">Learn more about Premium</a>
                  <p className="cta-detail subhead-bold">Get a 14 day free trial</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="featured">
          <div className="container flex-col-center">
            <p className="section-title caption-1-custom text-light">Featured In</p>

            <ul className="featured-logos flex-repel">
              <li><img src={images.featured1.path} alt={images.featured1.alt} /></li>
              <li><img src={images.featured2.path} alt={images.featured2.alt} /></li>
              <li><img src={images.featured3.path} alt={images.featured3.alt} /></li>
              <li><img src={images.featured4.path} alt={images.featured4.alt} /></li>
              <li><img src={images.featured5.path} alt={images.featured5.alt} /></li>
            </ul>
          </div>
        </section>

        <section className="feature-social">
          <div className="container">
            <div className="feature-text">
              <h2 className="feature-title title-3">Join the largest golfing community</h2>
              <p className="body-medium">Whether you want to brag to your buddies or engage with golfers all over the world, Hole19's social feed allows you to share, tag and comment on your golfing highs and lows directly in the app.</p>
            </div>

            <div className="feature-device">
              <img src={images.deviceSocial} alt="Share Your Progress" />
            </div>
          </div>
        </section>

        <section className="more-features">
          <div className="container-small flex-center-all">
            <h2 className="section-title title-3">See what more we have for free</h2>

            <ul className="list grid-2">
              <li>
                <p className="feature-name headline-bold">Performance on the Web</p>
                <p className="feature-description body-medium">Visualise your scorecards on your laptop. Sign in the Hole19 website to check your performances.</p>
              </li>

              <li>
                <p className="feature-name headline-bold">Multiplayer</p>
                <p className="feature-description body-medium">Track your playing partners’ scores and have them receiving their scorecards by email after each round.</p>
              </li>

              <li>
                <p className="feature-name headline-bold">Stroke Play and Stableford</p>
                <p className="feature-description body-medium">Select the scoring mode that you prefer before your golf round.</p>
              </li>

              <li>
                <p className="feature-name headline-bold">Play Offline</p>
                <p className="feature-description body-medium">Download your course in advance and play without an internet connection.</p>
              </li>

              <li>
                <p className="feature-name headline-bold">Digital Scorecard</p>
                <p className="feature-description body-medium">Track of your golf score, and save every shot you take in one place.</p>
              </li>

              <li>
                <p className="feature-name headline-bold">Mapping Requests</p>
                <p className="feature-description body-medium">Can’t find your course or it is outdated? Our Customer Service team will take care of it with ease.</p>
              </li>
            </ul>
          </div>
        </section>

        <section className="download-app">
          <div className="container flex-center-all">
            <img src={images.iconApp} alt="Download Hole19 For Free" />

            <h3 className="section-title title-3">Download Now for Free</h3>
            <p className="section-subtitle body-medium">Join 2.8M+ golfers worldwide today</p>

            <StoreButtons />
          </div>
        </section>

      </div>
    );
  }
}
