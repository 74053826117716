import React from 'react';
import Loader from '../../Loader.es6';

import CourseProfileHelpers from '../../../helpers/courseProfile';

export default class Scorecard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tees: [] };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.publicId !== this.props.course.publicId) {
      this.updateScorecard();
    }
  }

  componentDidMount() {
    this.updateScorecard();
  }

  updateScorecard(coursePublicId = this.props.course.publicId) {
    this.setState({ tees: [] }, () => {
      CourseProfileHelpers.fetchScorecard(this.props.service, coursePublicId)
        .then((json) => this.setState({ tees: json.tees }));
    });
  }

  render() {
    return (
      <div className="course-content-scorecard">
        <div className="section-title no-border">
          <h3 className="caption-1-custom text-light">Scorecard</h3>
        </div>
        {this.state.tees.length === 0 ? <div className="content-loading"><Loader /></div>
          : (
            <div className="scorecard">
              <ol className="scorecard-row">
                <li><p className="subhead text-light">Tee</p></li>
                <li><p className="subhead text-light">Distance</p></li>
                <li><p className="subhead text-light">PAR</p></li>
                <li><p className="subhead text-light">CR</p></li>
                <li><p className="subhead text-light">SR</p></li>
              </ol>
              {this.state.tees.map((tee) => (
                <ol key={tee.name} className="scorecard-row">
                  <li><p className="body-heavy">{tee.name}</p></li>
                  <li className="-center"><p className="body-regular">{tee.distance}</p></li>
                  <li className="-center"><p className="body-regular">{tee.par}</p></li>
                  <li className="-center"><p className="body-regular">{tee.course_rating}</p></li>
                  <li className="-center"><p className="body-regular">{tee.slope_rating}</p></li>
                </ol>
              ))}
            </div>
          )}
      </div>
    );
  }
}
