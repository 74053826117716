import React from 'react';

class PremiumOffer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topBar: 'default',
    };

    this.images = props.images;
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        this.setState({ topBar: 'visible' });
      } else {
        this.setState({ topBar: 'default' });
      }
    });
  }

  render() {
    return (
      <div>
        <div className={`top-bar ${this.state.topBar}`}>
          <div className="container flex-repel">
            <a href="/" className="logo">Hole19 Golf</a>
            <a href="/premium/checkout?product=premium_1_year&promocode=50OFF&from_premium=50off" className="top-bar-cta button-small">Get 50% OFF</a>
          </div>
        </div>

        <section className="hero-page">
          <div className="container-small container-flex">
            <div className="hero-content">
              <h1 className="hero-title title-1 text-white">
                <span>50% Off</span>
                {' '}
                Premium Pro
                {' '}
                <br />
                1 Year
              </h1>
              <p className="hero-intro headline text-white">Improve your game faster with full access to all Premium Pro features for less.</p>
              <a href="/premium/checkout?product=premium_1_year&promocode=50OFF&from_premium=50off" className="button-regular hero-button">Subscribe Now</a>
            </div>
          </div>
        </section>

        <section className="features-main">
          <div className="container-10">
            <header className="flex-col-center">
              <h2 className="section-title title-5">What's Included</h2>
            </header>

            <div className="features">
              <div className="row">
                <div className="feature-card shot-by-shot">
                  <div className="feature-device">
                    <img src={this.images.deviceShotByShot} />
                  </div>

                  <div className="feature-details">
                    <h3 className="feature-title title-3">Shot-by-Shot</h3>
                    <p className="feature-description headline-bold">Track each shot you make during your round for the ultimate in golf performance feedback.</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="feature-card vertical watch">
                  <div className="feature-device">
                    <img src={this.images.deviceWatch} />
                  </div>

                  <div className="feature-details">
                    <h3 className="feature-title title-3">Watch Scoring</h3>
                    <p className="feature-description headline-bold">Full input on your wrist. Your on-course experience just got even quicker and easier.</p>
                  </div>
                </div>

                <div className="feature-card vertical hcp">
                  <div className="feature-device">
                    <img src={this.images.deviceHCP} />
                  </div>

                  <div className="feature-details">
                    <h3 className="feature-title title-3">Handicap Calculator</h3>
                    <p className="feature-description headline-bold">Calculate & receive a more accurate handicap that will give you deeper insight into your game.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-more">
          <div className="container-10">
            <header className="flex-col-center">
              <h2 className="section-title title-5">And More</h2>
            </header>

            <div className="features">
              <ul>
                <li className="feature shot-tracker title-6"><span>Shot Tracker</span></li>
                <li className="feature highlights title-6"><span>Highlights</span></li>
                <li className="feature hcp title-6"><span>Handicap Simulator</span></li>
                <li className="feature no-ads title-6"><span>Ads</span></li>
                <li className="feature notes title-6"><span>Notes</span></li>
                <li className="feature club-stats title-6"><span>Club Statistics</span></li>
                <li className="feature auto-change-hole title-6"><span>Auto-Change Hole</span></li>
                <li className="feature club-rec title-6"><span>Club Recommendation</span></li>
                <li className="feature ar title-6"><span>Augmented Reality</span></li>
                <li className="feature match-play title-6"><span>Match Play</span></li>
                <li className="feature distance-tracker title-6"><span>Distance Tracker</span></li>
                <li className="feature customer-service title-6"><span>Priority Customer Service</span></li>
              </ul>
            </div>
          </div>
        </section>

        <section className="review">
          <div className="container flex-center-h">
            <p className="quote headline-bold">Hole19 is simply terrific! Easy, quick, accurate… runs all by itself! All I have to do is grab the right club and hit it straight!</p>
            <p className="author title-6">Mitch Adler, USA</p>
          </div>
        </section>

        <section className="cta">
          <div className="container">
            <div className="card">
              <div className="card-content flex-repel">
                <div className="left">
                  <h1 className="card-title title-1">
                    50% Off
                    <br />
                    <span className="title-2">Pro 1 Year</span>
                  </h1>
                </div>

                <div className="right">
                  <a href="/premium/checkout?product=premium_1_year&promocode=50OFF&from_premium=50off" className="button-regular">Subscribe Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PremiumOffer;
