import React from 'react';
import StoreButtons from './StoreButtons.es6';

export default class CommonHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMobileExpanded: false, userMenuOpen: false, sign_in_url: '/users/sign_in' };
    this.controlMobileExpansion = this.controlMobileExpansion.bind(this);
    this.user = props.user;
  }

  controlMobileExpansion() {
    this.setState((prevState) => ({ isMobileExpanded: !prevState.isMobileExpanded }));

    if (this.state.isMobileExpanded) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  controlUserMenu = () => {
    this.setState((prevState) => ({ userMenuOpen: !prevState.userMenuOpen }));
  };

  render() {
    const {
      consent_alert: consentAlert,
      absolute_header: absoluteHeader,
      user_centered_header: userCenteredHeader,
      sign_in_button_high_contrast: signInButtonHighContrast,
      show_store_buttons: showStoreButtons,
      theme,
    } = this.props;

    const absoluteClass = absoluteHeader ? 'header-absolute' : '';
    const userCenteredHeaderClass = userCenteredHeader ? 'header-user-centered' : '';
    const mobileClass = this.state.isMobileExpanded ? 'open' : '';
    const themeClass = theme === 'white' ? 'header-main-white' : '';
    const logoClass = theme === 'white' ? 'logo-white' : '';
    const signInButtonClass = signInButtonHighContrast ? 'text-primary-2' : 'text-primary-6';

    return (
      <header className={`${themeClass} ${absoluteClass} ${userCenteredHeaderClass} header-main`}>
        <nav className={`${mobileClass} menu-mobile`}>
          <div className="links">
            <a href="/golf-courses" className="menu-main-item button-1">Discover Courses</a>
            <a href="/premium" className="menu-main-item button-1">Premium</a>
            <a href="/wear" className="menu-main-item button-1">Wearables</a>
            <a href="//blog.hole19golf.com" className="menu-main-item button-1">Blog</a>
            <a href="/shop" className="menu-main-item button-1">Shop</a>
            <a href="//hole19.zendesk.com" target="_blank" className="menu-main-item button-1" rel="noreferrer">Support</a>

            {this.user.full_name
              ? (
                <div className="logged-user-menu">
                  <div className="logged-user-info">
                    {this.props.user.premium_at
                      ? <div className="premium-badge" />
                      : null}
                    <p>{this.user.full_name}</p>
                    <img className="logged-user-avatar avatar-round" alt="avatar" src={this.user.avatar_url} />
                    {consentAlert && (<div className="notification-alert" />)}
                  </div>
                  <div className="links-wrapper">
                    <ul className="logged-user-menu-links">
                      <li><a href={this.props.performance_url} className="button-1">Performance</a></li>

                      {this.props.user.premium_at
                        ? <li><a href={this.props.premium_subscription_url} className="button-1">Subscription</a></li>
                        : <li><a href="/premium" className="button-1 text-blue">Upgrade to Premium</a></li>}

                      <li>
                        {consentAlert && (<div className="notification-alert" />)}
                        <a href={this.props.notification_preferences} className="button-1">Emails and Notifications</a>
                      </li>

                      <li>
                        <form action="/users/sign_out" method="post">
                          <input type="hidden" name="authenticity_token" value={this.props.csrf_token} readOnly />
                          <input className="button-1 text-light" id="logout-button" type="submit" value="Sign Out" />
                        </form>
                      </li>
                    </ul>
                  </div>
                </div>
              )
              : <a itemProp="url" href={this.state.sign_in_url} className="menu-main-item button-1 text-primary-2">Sign In</a>}
          </div>
        </nav>

        <div className="container flex-repel">
          <a href="/" className={`${logoClass} logo`}>Hole19 Golf</a>

          <div className="top-bar-store-buttons">
            {showStoreButtons && <StoreButtons showOnlySupportedStore /> }
          </div>

          <div className="button-menu-mobile-wrapper">
            <div className={`${mobileClass} button-menu-mobile`} onClick={this.controlMobileExpansion} />
          </div>

          <nav className="menu-main">
            <a itemProp="url" href="/golf-courses" className="menu-main-item button-2">Discover Courses</a>
            <a itemProp="url" href="/premium" className="menu-main-item button-2">Premium</a>
            <a itemProp="url" href="/wear" className="menu-main-item button-2">Wearables</a>
            <a itemProp="url" href="//blog.hole19golf.com" className="menu-main-item button-2">Blog</a>
            <a itemProp="url" href="/shop" className="menu-main-item button-2">Shop</a>
            <a itemProp="url" href="//hole19.zendesk.com" target="_blank" className="menu-main-item button-2" rel="noreferrer">Support</a>

            {this.user.full_name
              ? (
                <div className="user-area">
                  <div className="menu-main-item logged-user">
                    <img className="logged-user-menu-trigger" onClick={this.controlUserMenu} src={this.user.avatar_url} />
                    {consentAlert && (<div className="notification-alert" />)}

                    {this.state.userMenuOpen
                      ? (
                        <div className="logged-user-menu">
                          <div className="logged-user-info">
                            {this.props.user.premium_at
                              ? <div className="premium-badge" />
                              : null}
                            <img className="logged-user-avatar" src={this.user.avatar_url} />
                            <p>{this.user.full_name}</p>
                          </div>

                          <ul className="logged-user-menu-links">
                            <li><a href={this.props.performance_url} className="button-2">Performance</a></li>

                            {this.props.user.premium_at
                              ? <li><a href={this.props.premium_subscription_url} className="button-2">Subscription</a></li>
                              : <li><a href="/premium" className="button-2 text-blue">Upgrade to Premium</a></li>}
                            <li>
                              <a href={this.props.notification_preferences} className="button-2">
                                Emails and Notifications
                                {consentAlert && (<div className="notification-alert" />)}
                              </a>
                            </li>
                            <li>
                              <form action="/users/sign_out" method="post">
                                <input type="hidden" name="authenticity_token" value={this.props.csrf_token} readOnly />
                                <input className="button-2 text-light" id="logout-button" type="submit" value="Sign Out" />
                              </form>
                            </li>
                          </ul>
                        </div>
                      )
                      : null}
                  </div>
                </div>
              )
              : (
                <div className="menu-main-item button-2">
                  <a itemProp="url" href={this.state.sign_in_url} className={signInButtonClass}>Sign In</a>
                </div>
              )}
          </nav>
        </div>
      </header>
    );
  }
}
