import React from 'react';

function SubscriptionSave({
  discardHandler,
  saveHandler,
  showButtonLoader,
}) {
  return (
    <div className="save-changes-bar">
      <div className="container-6">
        <a href="#" onClick={discardHandler} className="cta-secondary button-1 text-grey-3">
          Discard
        </a>
        {showButtonLoader ? (
          <button type="submit" name="button-loading" className="cta-main button-regular loading">Save Changes</button>
        ) : (
          <button
            className="cta-main button-regular"
            type="submit"
            onClick={saveHandler}
          >
            Save Changes
          </button>
        )}
      </div>
    </div>
  );
}

export default SubscriptionSave;
