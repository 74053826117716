import React from 'react';
import nextId from 'lodash/uniqueId';

class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.value,
      id: nextId('toggle-'),
    };
  }

  handleChange = () => {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }), () => this.props.clickHandler(this.state.isChecked));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isChecked: nextProps.value });
  }

  render() {
    const { isChecked, id } = this.state;
    const orangeTheme = this.props.orange ? 'toggle--orange' : '';

    return (
      <div className={`input-toggle flex-center-v ${orangeTheme}`}>
        {Optional(!this.props.simple).map((index) => <span key={`status-${index}`}>{isChecked ? 'ON' : 'OFF'}</span>)}

        <input
          id={id}
          type="checkbox"
          name="toggle"
          checked={isChecked ? 'checked' : ''}
          onChange={this.handleChange}
        />

        <label htmlFor={id} />
      </div>
    );
  }
}

export default Toggle;
