import React from 'react';

function ErrorScreen(props) {
  const renderMessage = (status) => {
    switch (status) {
      case 404:
        return (
          <span>
            The page you were looking for doesn't exist.
            <br />
            You may have mistyped the address or the page may have moved.
          </span>
        );
      case 422:
        return (
          <span>
            The change you wanted was rejected.
            <br />
            Maybe you tried to change something you didn't have access to.
          </span>
        );
      default:
        return (
          <span>
            We&#039;re terribly embarassed but there seems to have been an error on our side.
          </span>
        );
    }
  };

  return (
    <div className="not-found">
      <div className="ho-container">
        <header>
          <a href="/" className="ho-logo">
            <p className="h19-logo-p">Hole19</p>
          </a>
        </header>

        <div className="error-message">
          <h1 className="title">Fooooore!</h1>
          <h4 className="subheader">Your Ball is Lost!</h4>

          <p>{renderMessage(props.error)}</p>
          <a href="/" className="ho-btn">Go to Home Page</a>
        </div>
      </div>
    </div>
  );
}

export default ErrorScreen;
