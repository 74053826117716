import React from 'react';
import FilterWhere from './globalSearch/Where.es6';
import CuratedList from './curatedLists/CuratedList.es6';
import Loader from './Loader.es6';
import CourseList from './courses/list/Index.es6';
import StoreButtons from './StoreButtons.es6';

import Optional from '../helpers/optional.es6';
import apiHelpers from '../helpers/api';
import DiscoverHelpers from '../helpers/discoverCourses';

class DiscoverCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      curatedLists: [],
      courses: [],
      place: props.place,
      initialLocation: '',
      next: '',
      isCoursesLoading: true,
      location: props.user_location,
      latitude: props.latitude,
      longitude: props.longitude,
      radius: props.radius,
    };

    this.pop = {
      token: props.token,
      api: props.pop_api_url,
    };

    this.userLocation = { ...props.user_location };

    if (props.place && props.place.name) {
      this.state.externalRef = props.place.external_ref;
      this.state.location = props.place;
    }
  }

  componentWillMount() {
    window.defaultCourseImages = this.props.default_course_images;
  }

  componentWillUnmount() {
    delete window.defaultCourseImages;
  }

  componentDidMount() {
    this.refreshToken();
    this.updateNavigation(this.state.place, this.state.latitude, this.state.longitude, this.state.radius);
    this.getAllData();
  }

  refreshToken = () => {
    apiHelpers.autoRefreshToken(() => this.pop.token, (data) => {
      apiHelpers.updateToken(this.pop, data);
    });
  }

  getAllData = ({
    lat = this.state.latitude, lon = this.state.longitude, place = this.state.place, radius = 100, address,
  } = {}) => {
    this.setState({
      curatedLists: [],
      courses: [],
      isCoursesLoading: true,
      newAddress: address,
      place,
    }, () => {
      let query = '';
      let placeInfo = {};

      if (place && place.slug && place.name) {
        query = `slug=${place.slug}`;
        placeInfo = {
          lat: place.latitude, lon: place.longitude, place, radius,
        };
      } else {
        query = `latitude=${lat}&longitude=${lon}&radius=${radius}`;
        placeInfo = { lat, lon, radius };
      }
      this.reloadCoursesAndLists(query, placeInfo);
    });
  }

  fetchPlace = ({
    externalRef = '', latitude = this.state.latitude, longitude = this.state.longitude, radius = this.state.radius,
  } = {}) => {
    DiscoverHelpers.fetchPlaceByExternalRef(this.pop, externalRef)
      .then((result) => this.setPlaceInfo(result))
      .catch(() => this.setPlaceCoordinates(latitude, longitude, radius));
  }

  reloadCourses = ({
    lat, lng, place, radius,
  } = {}) => {
    const query = DiscoverHelpers.buildCoursesQueryString(lat, lng, place, radius);
    this.fetchCourses(query, false);
  }

  loadMoreCourses = () => {
    this.fetchCourses(this.state.next, true);
  }

  fetchCourses = (query, append) => {
    DiscoverHelpers.fetchCourses(this.pop, query)
      .then((result) => this.setCourses(result, append))
      .catch(() => this.setCourses([], append));
  }

  setCourses = (result, append) => {
    if (result.course_identities && result.course_identities.length) {
      const courses = this.generateCourses(result.course_identities, append);
      this.setState({ courses, next: result.paging && result.paging.next, coursesEmpty: false });
    } else {
      this.setState({ courses: [], coursesEmpty: true });
    }
  }

  setPlaceInfo = (result) => {
    this.setState({ place: result, location: result });
    this.updateNavigation(result);

    const curatedListsQuery = `slug=${result.slug}`;
    const coursesQuery = {
      lat: result.latitude, lng: result.longitude, place: result, radius: 100,
    };
    this.reloadCoursesAndLists(curatedListsQuery, coursesQuery);
  }

  setPlaceCoordinates = (latitude, longitude, radius) => {
    this.setState({ place: {} });
    this.updateNavigation(null, latitude, longitude, radius);
    const curatedListsQuery = `latitude=${latitude}&longitude=${longitude}&radius=${radius}`;
    this.reloadCoursesAndLists(curatedListsQuery, { lat: latitude, lng: longitude, radius });
  }

  reloadCoursesAndLists = (listsQuery, coursesQuery) => {
    this.reloadCuratedLists(listsQuery);
    this.reloadCourses(coursesQuery);
  }

  generateCourses(courses, append) {
    let coursesAux;
    if (append && this.state.courses.length) {
      coursesAux = this.state.courses;
      courses.map((course) => coursesAux.push(course));
    } else {
      coursesAux = courses;
    }
    return coursesAux;
  }

  reloadCuratedLists = (query) => {
    DiscoverHelpers.fetchCuratedLists(this.pop, query)
      .then(this.setCuratedLists)
      .catch(() => {});
  }

  setCuratedLists = (result) => {
    const stateParams = {
      isCoursesLoading: false,
      curatedLists: [],
      curatedListsEmpty: true,
    };

    let callback;

    if (result.data && result.data.length) {
      const { place, data: curatedLists } = result;
      stateParams.place = place;
      stateParams.curatedLists = curatedLists;
      stateParams.curatedListsEmpty = false;
    } else if (Object.keys(result.place).length) {
      stateParams.place = result.place;
      stateParams.curatedListsEmpty = false;
    } else {
      const placeName = this.state.newAddress || this.props.place_slug || this.userLocation.city;

      stateParams.place = { name: placeName };
      callback = this.setInitialLocation.bind(this, placeName);
    }

    this.setState(stateParams, callback);
  }

  setInitialLocation(place) {
    if (!this.state.initialLocation) {
      this.setState({ initialLocation: place });
    }
  }

  /**
   * Updates the browser navigation history so that the user gets
   * redirected to the right location, as specified by either a place or coordinates.
   */
  updateNavigation(place, latitude, longitude, radius) {
    const { default_path: defaultPath } = this.props;
    let title = 'Discover Golf Courses | Hole19';

    if (place && place.slug && place.name) {
      window.history.pushState({}, title, `${defaultPath}/${place.slug}`);
      title = `Golf Courses in ${place.name} | Hole19`;
    } else if (latitude && longitude) {
      let query = `?latitude=${latitude}&longitude=${longitude}`;

      if (radius) {
        query = query.concat(`&radius=${radius}`);
      }

      window.history.pushState({}, title, `${defaultPath}${query}`);
    }
    document.title = title;
  }

  buildCuratedLists() {
    return (
      this.state.curatedLists
        .filter((curatedList) => (curatedList.type !== 'hero_list'))
        .map((curatedList, index) => <CuratedList key={this.state.place.slug + index} placeSlug={this.state.place.slug} {...curatedList} />)
    );
  }

  render() {
    const { courses, next } = this.state;

    return (
      <section className="discover">
        <section className="ho-section-courses ho-hero-course ho-container">
          <h1>
            Discover courses in
            {this.state.location.name}
          </h1>
          <h2 className="subtitle">Browse through our 41.000+ golf courses. Find your next golf spot.</h2>
          <div className="global-search">
            <FilterWhere
              location={this.state.location.name}
              fetchHandler={(data) => this.fetchPlace({
                externalRef: data.externalRef, latitude: data.lat, longitude: data.lng, radius: data.radius,
              })}
              pop={this.pop}
            />
          </div>
        </section>
        {
          this.state.isCoursesLoading
            ? <div className="discover__loader"><Loader /></div>
            : (
              <div className="discover--container">
                <section className="ho-section-courses discover__content">
                  {this.buildCuratedLists()}
                </section>

                <section className="ho-section-courses discover__all-courses">
                  <CourseList next={next} courses={courses} clickHandler={this.loadMoreCourses} />
                </section>
              </div>
            )
        }
        {Optional(this.state.coursesEmpty && this.state.curatedListsEmpty).map(() => (
          <div key="no-results" className="discover__no-results">
            <p>We couldn't find any courses. Please try searching in a different location.</p>
          </div>
        ))}
        <div className="download-app">
          <h3 className="package-title title-4">Download the App</h3>
          <StoreButtons />
        </div>
        <section className="ho-section-courses discover__country-list">
          <p>Looking for other places or countries?</p>
          <a href="/countries">
            <i className="icn-globe" />
            View our country list
          </a>
        </section>
      </section>
    );
  }
}

export default DiscoverCourses;
