import React from 'react';
import moment from 'moment';
import Loader from '../../Loader.es6';
import ReviewsExpanded from './ReviewsExpanded.es6';

import CourseProfileHelpers from '../../../helpers/courseProfile';

export default class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.defaultAvatar = props.defaultAvatar;
    this.state = { maxReviews: 5 };
    this.changeExpandedState = this.changeExpandedState.bind(this);
    this.courseName = this.props.course.name;
    this.courseImage = this.props.course.images[0]
      ? this.props.course.images[0].url
      : '';
  }

  componentDidMount() {
    CourseProfileHelpers.fetchReviews(this.props.service, this.props.course.region_slug, this.props.course.slug)
      .then((json) => this.setState({ reviews: json.data }));
  }

  hideBodyScroll(state) {
    (function () {
      document.body.style.overflow = (state) ? 'auto' : 'hidden';
    }());
  }

  changeExpandedState() {
    this.setState((prevState) => ({ viewExpanded: !prevState.viewExpanded }));
    this.hideBodyScroll(this.state.viewExpanded);
  }

  generateReviewsSchema() {
    const { reviews } = this.state;

    if (!reviews || reviews.length === 0) {
      return null;
    }

    const reviewsSchema = reviews.map((review) => ({
      '@context': 'https://schema.org/',
      '@type': 'Review',
      itemReviewed: {
        '@type': 'GolfCourse',
        name: this.courseName,
        image: this.courseImage,
        address: {
          '@type': 'PostalAddress',
          streetAddress: this.props.course.address,
          addressRegion: this.props.course.region ? this.props.course.region.name : null,
        },
      },
      author: {
        '@type': 'Person',
        name: review.user.full_name,
      },
      reviewBody: review.description,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.rating,
      },
    }));

    return (<script type="application/ld+json">{JSON.stringify(reviewsSchema)}</script>);
  }

  render() {
    const { reviews } = this.state;
    return (
      <div id="course-reviews" className="course-content-reviews">
        <div className="section-title">
          <h3 className="caption-1-custom text-light">Reviews</h3>
          {this.state.reviews && this.state.reviews.length > this.state.maxReviews
            ? <a onClick={this.changeExpandedState} className="caption-1">View All</a> : null}
        </div>

        {this.state.viewExpanded
          ? <ReviewsExpanded reviews={this.state.reviews} course={this.props.course} unmount={this.changeExpandedState} defaultAvatar={this.defaultAvatar} /> : null}

        {reviews === undefined ? <div className="content-loading"><Loader /></div> : null}
        {reviews && reviews.length === 0 ? <p className="body-regular-secondary content-not-available">Reviews not available for this course.</p> : null}
        {reviews && reviews.length > 0
          ? (
            <ol className="reviews">
              {reviews.slice(0, this.state.maxReviews).map((review) => (
                <li key={Math.random()}>
                  <div className="review-header">
                    <img className="review-avatar avatar-40" role="presentation" src={review.user.avatar_url ? review.user.avatar.thumb.small : this.defaultAvatar} alt={review.user.full_name} />

                    <div className="review-header-info">
                      <p className="body-heavy">{review.user.full_name}</p>
                      <p className="subhead text-light">{moment(review.created_at).format('LL')}</p>
                    </div>

                    <div className="review-rating star-rating">
                      {
                      [1, 2, 3, 4, 5].reverse().map((star) => <div className={(review.rating >= star) ? 'active' : ''} key={star} />)
}
                    </div>
                  </div>

                  <div className="review-text">
                    <p>{review.description}</p>
                  </div>
                </li>
              ))}
            </ol>
          )
          : null}
        {this.generateReviewsSchema()}
      </div>
    );
  }
}
