/**
 * Determine the mobile operating system. This function returns one of 'iOS', 'Android', or 'unknown'.
 * Source: https://stackoverflow.com/a/21742107/3721762
 */
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent) && !/windows phone/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export function isIosDevice() {
  return getMobileOperatingSystem() === 'iOS';
}

export function isAndroidDevice() {
  return getMobileOperatingSystem() === 'Android';
}
