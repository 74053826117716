import React from 'react';
import moment from 'moment';
import CourseSelect from './NewLeaderboardFormCourseSelect.es6';

export default class NewLeaderboardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stableford: true };
  }

  onDateChange = (e) => {
    this.setState({ date: e.target.value });
  }

  setStableford = (newValue) => () => {
    this.setState({ stableford: newValue });
  }

  parseDates = (date) => {
    let start = moment(date).toDate();
    let end = moment(date).toDate();

    if (Number.isNaN(start)) {
      start = undefined;
    } else {
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start = start.toISOString();
    }

    if (Number.isNaN(end)) {
      end = undefined;
    } else {
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end = end.toISOString();
    }

    return { start, end };
  };

  render() {
    const createUrl = '/performance/leaderboards/';
    const today = moment().format('YYYY-MM-DD');
    const dates = this.parseDates(this.state.date);
    const scoringMode = this.state.stableford ? 'stableford' : 'stroke_play';

    return (
      <form method="post" action={createUrl}>
        <header className="header-new-leaderboard">
          <input type="text" name="title" className="leaderboard-title" placeholder="Insert Leaderboard Name" required />
        </header>

        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={this.props.authenticityToken} />

        <div className="input-group">
          <label htmlFor="date" className="input-label">Date</label>
          <input type="date" name="date" min={today} onChange={this.onDateChange} placeholder="yyyy-MM-dd" required />
          <input type="hidden" name="started_at" value={dates.start} required />
          <input type="hidden" name="ended_at" value={dates.end} required />
        </div>

        <CourseSelect pop_api={this.props.popApi} />

        <div className="input-group group-radios">
          <label className="input-label">Scoring Mode</label>

          <div className="controls horizontal">
            <div className="input-radio">
              <input type="radio" name="mode" id="stableford" onChange={this.setStableford(true)} defaultChecked={this.state.stableford} required />
              <label htmlFor="stableford">Stableford</label>
            </div>

            <div className="input-radio">
              <input type="radio" name="mode" id="strokeplay" onChange={this.setStableford(false)} defaultChecked={!this.state.stableford} required />
              <label htmlFor="strokeplay">Stroke Play</label>
            </div>
          </div>

          <input type="hidden" name="scoring_mode" value={scoringMode} required />
        </div>

        <div className="action-bar">
          <input className="main-action button-create button-regular" type="submit" value="Create Leaderboard" disabled={dates.start === undefined} />
        </div>
      </form>
    );
  }
}
