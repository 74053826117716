import React from 'react';
import moment from 'moment';

export default class Forecast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDailyForecast: false,
    };
    this.handleForecastDaily = this.handleForecastDaily.bind(this);
  }

  handleForecastDaily() {
    this.setState((prevState) => ({ showDailyForecast: !prevState.showDailyForecast }));
  }

  temperatureToString(temperature, withUnit = true) {
    let value = temperature;
    let unit = 'C';

    if (this.props.unit === 'fahreneit') {
      value = temperature * 1.8 + 32;
      unit = 'F';
    }

    if (withUnit === false) {
      unit = '';
    }

    return (
      <div>
        {parseInt(value, 10)}
        º
        <span className="forecast-temperature-unit">{unit}</span>
      </div>
    );
  }

  forecastDaily(forecast) {
    const rows = [];
    for (let i = 2; i <= 6; i += 1) {
      rows.push(
        <div key={`forecast-${i}`} className="forecast">
          <div className={`forecast-icon icon-${forecast.daily[i].icon}`} />
          <div className="forecast-time">{moment.unix(forecast.daily[i].time).utc().format('ddd')}</div>
          <div className="forecast-temperature">{this.temperatureToString(forecast.daily[i].temperatureMax, false)}</div>
        </div>,
      );
    }
    return rows;
  }

  render() {
    const { forecast } = this.props;
    if (!this.props.forecast) {
      return null;
    }

    return (
      <div className={`course-forecast arrow-${this.state.showDailyForecast ? 'down' : 'up'}`}>
        <div className="forecast forecast-today">
          <div className="forecast-container">
            <div className={`forecast-icon icon-${forecast.currently.icon}`} />
            <div className="forecast-time">Today</div>
            <div className="forecast-temperature">{this.temperatureToString(forecast.currently.temperature)}</div>
            {this.state.showDailyForecast
              ? <div className="forecast-arrow" onClick={this.handleForecastDaily}><div className="forecastArrowDown" /></div>
              : <div className="forecast-arrow" onClick={this.handleForecastDaily}><div className="forecastArrowUp" /></div>}
          </div>
        </div>

        <div className="forecast-daily">
          {this.forecastDaily(forecast)}
        </div>
      </div>
    );
  }
}
