import React from 'react';

function Rank({ rank, defaultAvatar }) {
  return (
    <li>
      <span className="leaderboard-rank">{rank.rank}</span>
      <img className="leaderboard-avatar avatar-40" role="presentation" src={rank.user.avatar_url ? rank.user.avatar.thumb.small : defaultAvatar} alt={rank.user.full_name} />
      <p className="leaderboard-name body-heavy">{rank.user.full_name}</p>
      <h5 className="leaderboard-score subhead">{rank.score}</h5>
    </li>
  );
}

export default Rank;
