import React from 'react';

function Loader({ color, size, center } = {}) {
  return (
    <div className={`loader ${color || 'default'} size-${size || '24'} ${center ? `center-${center}` : ''}`}>
      <div className="spinner" />
    </div>
  );
}

export default Loader;
