import React from 'react';
import Team from './Team.es6';

function Company() {
  return (
    <div>
      <div className="ho-container-small">
        <section className="hero-padding">
          <h1 className="title-2">We connect the world of golf</h1>
        </section>
      </div>

      <div className="section-hero-image company-bg" />

      <div className="container-small">
        <section className="section-company-about">
          <div className="ho-container-small">
            <p className="headline">
              We are obsessed with connecting you to the world of golf. Whether by allowing you to discover great courses, gaining a better understanding of your game, or connecting with golf friends from all over the world, our team works hard to give you a better golfing experience.
            </p>

            <blockquote>
              <p>I didn’t have the right tools to understand how to play better. That’s how the idea came to life.</p>
              <cite>Anthony Douglas, CEO</cite>
            </blockquote>

            <h3>The team</h3>
            <p className="headline">
              Young, energetic and talented, our team is made up of designers, engineers, course mapping experts, marketers and golfers. Find out who the Hole19'ers are below!
            </p>
          </div>
        </section>
      </div>

      <section className="section-company-team">
        <div className="ho-container">
          <Team />
        </div>
      </section>
    </div>
  );
}

export default Company;
