import React from 'react';
import SubscriptionHelpers from '../../helpers/subscriptions';
import Loader from '../Loader.es6';

const withPreferences = ({
  url,
  api,
  userEmail,
  title,
  showAlertHandler,
  showSaveAllBoxHandler,
  authToken,
  toWrapped = {}, // additional params to pass to the wrapped component
}) => (WrappedComponent) => class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSubscriptions: [],
      subscriptions: [],
      alertCSSClass: 'success', // eslint-disable-line react/no-unused-state
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getSubscriptions();
    this.setGlobalHandlers();
  }

  setGlobalHandlers = () => {
    window.subscriptions = window.subscriptions || {};
    window.subscriptions.saveHandlers = window.subscriptions.saveHandlers || {};
    window.subscriptions.discardHandlers = window.subscriptions.discardHandlers || {};
    window.subscriptions.saveHandlers[title] = this.saveAllSubscriptions;
    window.subscriptions.discardHandlers[title] = this.discardChanges;
  };

  getSubscriptions() {
    const params = (authToken && SubscriptionHelpers.setParams({ email: userEmail, token: authToken })) || {};

    SubscriptionHelpers.getSubscriptions({ api, url }, params)
      .then((subscriptions) => this.setState({ subscriptions, initialSubscriptions: subscriptions, isLoading: false }));
  }

  discardChanges = () => new Promise((resolve) => {
    this.setState((prevState) => ({ subscriptions: prevState.initialSubscriptions }), resolve);
  });

  saveAllSubscriptions = () => new Promise((resolve, reject) => {
    const subscriptions = JSON.parse(JSON.stringify(this.state.subscriptions));
    const params = SubscriptionHelpers.setParams({ email: userEmail, subscriptions, token: authToken });

    SubscriptionHelpers.updateSubscription({ api, url }, params)
      .then(() => this.setState({ initialSubscriptions: subscriptions }))
      .then(() => resolve(showAlertHandler && showAlertHandler('success')))
      .catch((e) => { this.setState((prevState) => ({ subscriptions: prevState.initialSubscriptions })); reject(e); });
  });

  updateSubscriptionHandler = (key, value, callback = () => {}) => {
    this.setState((prevState) => {
      const subscriptions = JSON.parse(JSON.stringify(prevState.subscriptions));
      const subscriptionIndex = subscriptions.findIndex((obj) => obj.key === key);
      subscriptions[subscriptionIndex].value = value;

      return { subscriptions };
    }, () => {
      if (callback) {
        callback();
      }
      if (showSaveAllBoxHandler) {
        showSaveAllBoxHandler();
      }
    });
  };

  render() {
    const params = {
      userEmail,
      initialSubscriptions: this.state.initialSubscriptions,
      subscriptions: this.state.subscriptions,
      changeHandler: this.updateSubscriptionHandler,
    };

    return this.state.isLoading ? (
      <Loader center="horizontal" />
    ) : (
      <WrappedComponent {...params} {...toWrapped} />
    );
  }
};

export default withPreferences;
