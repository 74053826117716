import React from 'react';
import CountriesList from './CountriesList.es6';
import FilterWhere from '../globalSearch/Where.es6';

import DiscoverHelpers from '../../helpers/discoverCourses/index';

class Countries extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showNoResultsMessage: false };

    this.countries = this.props.countries.countries || [];
    this.pop = {
      token: props.token,
      api: props.pop_api_url,
    };
  }

  goToPlace = ({ externalRef } = {}) => {
    DiscoverHelpers.fetchPlaceByExternalRef(this.pop, externalRef)
      .then((response) => {
        this.setState({ showNoResultsMessage: false }, () => {
          window.location.href = `/golf-courses/${response.slug}`;
        });
      })
      .catch(() => this.setState({ showNoResultsMessage: true }));
  }

  render() {
    return (
      <div>
        <section className="ho-hero-course ho-container">
          <h2>Discover Courses</h2>
          <h3>
            in
            {' '}
            <strong>{this.countries.length}</strong>
            {' '}
            countries
          </h3>

          <div className="global-search">
            <FilterWhere
              fetchHandler={this.goToPlace}
              pop={this.pop}
            />
          </div>
          {Optional(this.state.showNoResultsMessage).map(() => <p className="no-results" key="no-courses">No Golf Courses in this area.</p>)}
        </section>

        <CountriesList countries={this.countries} />
      </div>
    );
  }
}

export default Countries;
