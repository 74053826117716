import React from 'react';
import moment from 'moment';
import Leaderboard from './shared/Leaderboard.es6';
import LeaderboardViewModel from './shared/LeaderboardViewModel.es6';
import Modals from './shared/ShowModals.es6';

const {
  InviteModal, ShareModal, DeleteModal, LeaveModal, PlayersListModal, TutorialModal,
} = Modals;

function LeaderboardForm({
  method, url, authenticityToken, children, classes,
}) {
  return (
    <form method="post" action={url} className={classes}>
      <input type="hidden" name="_method" value={method || 'patch'} />
      <input type="hidden" name="authenticity_token" value={authenticityToken} />
      {children}
    </form>
  );
}

function LeaderboardButtonForm({
  method, url, authenticityToken, text,
}) {
  return (
    <LeaderboardForm method={method} url={url} authenticityToken={authenticityToken}>
      <div className={`action ${text.toLowerCase()}`}>
        <button type="submit" className="button-small icon-plus green">{text}</button>
      </div>
    </LeaderboardForm>
  );
}

function LeaderboardButtonAnchor({ text, onClick }) {
  return <a href="#" onClick={onClick} className={`action ${text.toLowerCase()} caption-1-bold text-light`}>{text}</a>;
}

function LeaderboardFakeButton({ text, onClick }) {
  return <a href="#" onClick={onClick} className={`action ${text.toLowerCase()} button-small icon-check green-outline`}>{text}</a>;
}

class RoundLeaderboardShow extends React.Component {
  constructor(props) {
    super(props);

    let scoringMode;
    const validScoringModes = ['stroke_play_gross', 'stroke_play_net', 'stableford_gross', 'stableford_net'];
    if (validScoringModes.includes(props.scoring_mode)) {
      scoringMode = props.scoring_mode;
    } else {
      const isStableford = props.data.leaderboard.scoring_mode === 'stableford';
      scoringMode = isStableford ? 'stableford_net' : 'stroke_play_gross';
    }

    this.state = {
      scoringMode,
      inviteVisible: !!props.open_invite,
      deleteVisible: false,
      leaveVisible: false,
      shareVisible: false,
      playersListModalVisible: false,
      tutorialModalVisible: false,
    };
  }

  componentDidMount() {
    // auto reload page every 60 seconds
    setTimeout(() => {
      window.location.reload(false);
    }, 60000);
  }

  didChangeScoringMode = (scoringModeUrl) => (e) => { window.location.href = `${scoringModeUrl}${e.target.value}`; };

  toggleState = (key) => () => { this.setState((prevState) => ({ [key]: !prevState[key] })); };

  parseLeaderboard = (data) => {
    const start = moment(data.started_at).toDate();
    const end = moment(data.ended_at).toDate();

    return {
      slug: data.slug,
      title: data.title,
      courseName: data.course.name,
      defaultScoringMode: (data.scoring_mode || 'stroke_play').split('_').join(' '),
      tees: data.tees.map((tee) => tee.name).join(', '),
      isLive: moment().toDate() >= start && moment().toDate() <= end,
      dateText: start.toDateString(),
      dateDetailsText: `From ${start.toString()} to ${end.toString()}`,
      canUseInvite: !!data.can_invite,
      canUseJoin: !data.joined && !this.props.block_join,
      canUseLeave: !!data.joined,
      canUseDestroy: !!data.can_destroy,
    };
  };

  leaderboardUrls = (window, slug) => {
    const baseUrl = (window.location.href || '').split(window.location.pathname)[0];
    const basePath = `/performance/leaderboards/${slug}`;
    const query = this.props.in_round ? '?in_round=true&' : '?';

    return {
      join: `${basePath}/join`,
      leave: `${basePath}/leave`,
      destroy: `${basePath}/`,
      invite: `${baseUrl}${basePath}/`,
      share: `${baseUrl}${basePath}/public/`,
      scoringMode: `${baseUrl}${basePath}/${query}scoring_mode=`,
    };
  };

  render() {
    const { defaultAvatar } = this.props.images;
    const leaderboard = this.parseLeaderboard(this.props.data.leaderboard);
    const viewModel = LeaderboardViewModel(this.props.data.leaderboard, this.state.scoringMode, defaultAvatar);
    const authenticityToken = this.props.form_csrf_token;
    const urls = this.leaderboardUrls(window, leaderboard.slug);

    return (
      <main className="page-leaderboard">
        <TutorialModal visible={this.state.tutorialModalVisible} onCloseClick={this.toggleState('tutorialModalVisible')} />

        <PlayersListModal visible={this.state.playersListModalVisible} onCloseClick={this.toggleState('playersListModalVisible')} players={viewModel.users} />

        {Optional(leaderboard.canUseInvite).map(() => (
          <InviteModal url={urls.invite} visible={this.state.inviteVisible} onCloseClick={this.toggleState('inviteVisible')} />
        ))}

        <ShareModal url={urls.share} visible={this.state.shareVisible} onCloseClick={this.toggleState('shareVisible')} />

        {Optional(leaderboard.canUseLeave).map(() => (
          <LeaveModal visible={this.state.leaveVisible} onCloseClick={this.toggleState('leaveVisible')}>
            <LeaderboardForm url={urls.leave} authenticityToken={authenticityToken} classes="modal-actions">
              <input type="submit" className="button red-outline" value="Leave Leaderboard" />
            </LeaderboardForm>
          </LeaveModal>
        ))}

        {Optional(leaderboard.canUseDestroy).map(() => (
          <DeleteModal visible={this.state.deleteVisible} onCloseClick={this.toggleState('deleteVisible')}>
            <LeaderboardForm url={urls.destroy} method="delete" authenticityToken={authenticityToken} classes="modal-actions">
              <input type="submit" className="button red-outline" value="Delete Leaderboard" />
            </LeaderboardForm>
          </DeleteModal>
        ))}

        <header className={`header-leaderboard ${this.props.webview ? 'webview' : ''} ${this.props.in_round ? 'in-round' : ''}`}>
          <div className="container">
            <div className="content">
              <div className="left-info">
                {Optional(!this.props.webview).map(() => (
                  <a className="action-back subhead-bold text-light" href="/performance/leaderboards">Leaderboards</a>
                ))}

                <p className="event-date subhead text-light" title={leaderboard.dateDetailsText}>
                  {leaderboard.dateText}
                  {Optional(leaderboard.isLive).map(() => <span className="label label-green">Live</span>)}
                </p>

                <h1 className="event-name title-2">
                  {leaderboard.title || leaderboard.courseName}
                </h1>

                <div className="leaderboard-info flex-center-v">
                  <span className="course-name headline text-light">{leaderboard.courseName}</span>

                  <div className="game-specific">
                    <p className="holes-count subhead text-light">
                      {viewModel.holes.length}
                      {' '}
                      Holes
                    </p>
                    <div className="scoring-mode label">{leaderboard.defaultScoringMode}</div>
                    <span className="tees subhead text-light">{leaderboard.tees}</span>
                  </div>
                </div>
              </div>

              <div className="right-actions">
                <div className="players" onClick={this.toggleState('playersListModalVisible')}>
                  {viewModel.users.length <= 3
                    ? viewModel.users.map((user, index) => (<img key={`${index}+${user.fullName}`} className="avatar avatar-32 round" src={user.avatarUrl} />))
                    : (
                      <div>
                        <img className="avatar avatar-32 round" src={viewModel.users[0].avatarUrl} />
                        <img className="avatar avatar-32 round" src={viewModel.users[1].avatarUrl} />
                        <div className="avatar avatar-more">
                          <p className="caption-2-bold text-light">{viewModel.users.length}</p>
                        </div>
                      </div>
                    )}
                </div>

                <div className="main-actions flex-center-v">
                  <div className="primary-actions">
                    {Optional(leaderboard.canUseJoin).map(() => (
                      <LeaderboardButtonForm url={urls.join} authenticityToken={authenticityToken} text="Join" />
                    ))}

                    {Optional(leaderboard.canUseLeave).map(() => (
                      <LeaderboardFakeButton text="Joined" onClick={this.toggleState('leaveVisible')} />
                    ))}
                  </div>

                  <div className="secondary-actions">
                    {Optional(leaderboard.canUseDestroy).map(() => (
                      <LeaderboardButtonAnchor text="Delete" onClick={this.toggleState('deleteVisible')} />
                    ))}

                    <a className="action share caption-1-bold text-light" href="#" onClick={this.toggleState('shareVisible')}>Share</a>

                    {Optional(leaderboard.canUseInvite).map(() => (
                      <a className="action invite caption-1-bold text-light" href="#" onClick={this.toggleState('inviteVisible')}>Invite</a>
                    ))}
                  </div>
                </div>

                <div className="scoring-mode-selector">
                  <select onChange={this.didChangeScoringMode(urls.scoringMode)} value={this.state.scoringMode} className="dropdown-2-compact">
                    <option value="stroke_play_gross">Stroke Play Gross</option>
                    <option value="stroke_play_net">Stroke Play Net</option>
                    <option value="stableford_gross">Stableford Gross</option>
                    <option value="stableford_net">Stableford Net</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="holes-details">
              <div className="holes">
                <p className="caption-2-bold text-light">Holes</p>

                <div className={`holes-list ${viewModel.holes.length}`}>
                  {viewModel.holes.map((hole) => <p className="hole caption-2-bold text-light">{hole.sequence}</p>)}
                </div>
              </div>

              <div className="par">
                <p className="caption-2-bold text-light">PAR</p>

                <div className={`par-list ${viewModel.holes.length}`}>
                  {viewModel.holes.map((hole) => <p className="hole caption-2 text-lighter">{hole.par}</p>)}
                </div>

                <div className="additional">
                  <p className="caption-2-bold text-light">{viewModel.scoreTitle}</p>
                  <p className="caption-2-bold text-light" data-content={viewModel.toParTitle.toLowerCase().replace(/\s/g, '-')}><span>{viewModel.toParTitle}</span></p>
                  <p className="caption-2-bold text-light">THRU</p>
                </div>
              </div>

              <div className="si">
                <p className="caption-2-bold text-light">S.I</p>

                <div className={`si-list ${viewModel.holes.length}`}>
                  {viewModel.holes.map((hole) => <p className="hole caption-2 text-lighter">{hole.strokeIndex}</p>)}
                </div>
              </div>
            </div>
          </div>
        </header>

        {viewModel.entries.length > 0
          ? <Leaderboard model={viewModel} holesPerTee={viewModel.holesPerTee} />

          : (
            <div className="empty">
              {Optional(leaderboard.canUseJoin).map(() => (
                <div className="tutorial-bar" onClick={this.toggleState('tutorialModalVisible')}>
                  <p className="subhead-bold text-blue">Add my round to this leaderboard</p>
                </div>
              ))}

              <div className="container flex-center-all">
                <p className="empty-message body text-light">Invite your friends to join the leaderboard.</p>
              </div>
            </div>
          )}
      </main>
    );
  }
}

export default RoundLeaderboardShow;
