import React from 'react';
import withPreferences from '../WithPreferences.es6';
import PreferencesList from '../PreferencesList.es6';
import SubscriptionList from './SubscriptionList.es6';
import ConsentSubscriptionSave from './ConsentSubscriptionSave.es6';
import SubscriptionSave from './SubscriptionSave.es6';
import Alert from '../../Alert.es6';

class NotificationPreferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailDescription:
        "Choose which emails you'd like to receive. Please note that you cannot opt-out of receiving messages, such as payment, security or legal notifications.",
      pushDescription:
        "Decide what you types of push notifications you'd like to see. Please note that you cannot opt-out of receiving notifications, such as payment, security or legal notifications.",
      showAlert: false,
      showConsentForm: props.show_consent_form,
      showForm: false,
    };

    this.alertsType = {
      success: {
        alertMessage: 'Subscriptions saved with success!',
        alertCSSClass: 'success',
      },
      warning: {
        alertMessage: 'Something went wrong...',
        alertCSSClass: 'warning',
      },
      terms: {
        alertMessage:
          'In order to continue you have to agree to our consent terms.',
        alertCSSClass: 'warning',
      },
    };
  }

  componentDidMount() {
    this.setState((prevState) => ({ showForm: !!prevState.showConsentForm }));
  }

  toggleAlertHandler = () => {
    this.setState((prevState) => ({ showAlert: !prevState.showAlert }));
  };

  showAlertHandler = (alertType) => {
    this.setState({
      showAlert: true,
      alertMessage: this.alertsType[alertType].alertMessage,
      alertCSSClass: this.alertsType[alertType].alertCSSClass,
    });
  };

  /**
   * Ninja function to execute save or discard methods on each children.
   * These childrens have the state of user subscriptions so we need to call each
   * one on each children
   */
  executePromises = (handlerType) => window.subscriptions[handlerType]
    .email()
    .then(window.subscriptions[handlerType].push)
    .catch(() => this.showAlertHandler('warning'));

  saveAllHandler = (e) => {
    e.preventDefault();

    this.setState({ showButtonLoader: true }, () => {
      this.executePromises('saveHandlers').then(() => {
        const params = { showButtonLoader: false };

        if (this.state.showConsentForm) {
          params.showConsentForm = false;
        }

        params.showForm = false;

        this.setState(params);
      });
    });
  };

  setInitialSubscriptionsState = (e) => {
    e.preventDefault();

    this.executePromises('discardHandlers').then(() => this.setState({ showButtonLoader: false, showForm: false }));
  };

  showSaveAllBoxHandler = () => {
    const { showConsentForm, showForm } = this.state;

    if (!showConsentForm && !showForm) {
      this.setState({ showForm: true });
    }
  };

  buildSaveForm = () => {
    const { showButtonLoader, showConsentForm, showForm } = this.state;

    if (showConsentForm && showForm) {
      return (
        <ConsentSubscriptionSave
          showButtonLoader={showButtonLoader}
          showAlertHandler={this.showAlertHandler}
          saveHandler={this.saveAllHandler}
        />
      );
    } if (showForm) {
      return (
        <SubscriptionSave
          showButtonLoader={showButtonLoader}
          discardHandler={this.setInitialSubscriptionsState}
          saveHandler={this.saveAllHandler}
        />
      );
    }
  };

  render() {
    const PushSubscriptionsList = withPreferences({
      url: 'api/web/subscriptions/push_notification',
      userEmail: this.props.user_email,
      api: this.props.pop_api_url,
      title: 'push',
      showAlertHandler: this.showAlertHandler,
      showSaveAllBoxHandler: this.showSaveAllBoxHandler,
    })(PreferencesList);

    const EmailSubscriptionsList = withPreferences({
      url: 'api/web/subscriptions/email',
      userEmail: this.props.user_email,
      api: this.props.pop_api_url,
      title: 'email',
      showAlertHandler: this.showAlertHandler,
      showSaveAllBoxHandler: this.showSaveAllBoxHandler,
    })(PreferencesList);

    const { emailDescription, pushDescription, showAlert } = this.state;

    return (
      <div className="preferences-sections-wrapper">
        {showAlert
          && (
          <Alert
            message={this.state.alertMessage}
            classes={this.state.alertCSSClass}
            onClick={this.toggleAlertHandler}
          />
          )}

        <SubscriptionList
          title="Email Preferences"
          description={emailDescription}
        >
          <EmailSubscriptionsList />
        </SubscriptionList>

        <SubscriptionList
          title="Push Notifications Preferences"
          description={pushDescription}
        >
          <PushSubscriptionsList />
        </SubscriptionList>

        {this.buildSaveForm()}
      </div>
    );
  }
}

export default NotificationPreferences;
