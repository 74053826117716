import React from 'react';
import SubscriptionList from '../notifications/SubscriptionList.es6';
import PreferencesList from '../PreferencesList.es6';
import PageTitleHeader from '../../pages/PageTitleHeader.es6';
import withPreferences from '../WithPreferences.es6';
import Alert from '../../Alert.es6';

const EMAIL_UNSUBSCRIBE_TITLE = 'email_unsubscribe';
const SAVE_TIMEOUT = 1500;
const ALERT_TIMEOUT = 2000;

class EmailUnsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubscribed: false,
      showAlert: false,
      alertMessage: false,
      alertCSSClass: '',
    };

    this.alertsType = {
      success: {
        alertMessage: 'Subscriptions saved with success!',
        alertCSSClass: 'success',
      },
      warning: {
        alertMessage: 'Something went wrong...',
        alertCSSClass: 'warning',
      },
    };

    this.subscription = props.subscription;
    this.friendly_subscription = props.friendly_subscription;

    this.saveTimeout = undefined;
  }

  hideAlertHandler = () => {
    this.setState({ showAlert: false });
  };

  showAlertHandler = (alertType) => {
    this.setState({
      showAlert: true,
      alertMessage: this.alertsType[alertType].alertMessage,
      alertCSSClass: this.alertsType[alertType].alertCSSClass,
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.hideAlertHandler, ALERT_TIMEOUT);
  };

  componentDidMount() {
    // We want to save everything (not only partial changes) when the component is loaded,
    // since it doesn't make sense for the backend state to be out of sync at this stage.
    this.changeHandler(this.subscription, false, false, false);
  }

  /**
   * @param {string} key identifies the subscription being changed
   * @param {boolean} scheduleSave whether to schedule save or save immediatly
   */
  changeHandler = (key, value, scheduleSave = true, notify = true) => {
    if (notify !== false) {
      notify = true;
    }

    // choose saving strategy (scheduled or imediate)
    const saveHandler = scheduleSave ? this.scheduleSave : window.subscriptions.saveHandlers[EMAIL_UNSUBSCRIBE_TITLE];

    const changingSub = this.props.subscriptions.find((sub) => sub.key === key);
    if (changingSub) {
      changingSub.value = value;
    }

    this.props.changeHandler(key, value, () => {
      // after changing, follow save strategy, notifying if needed
      const savePromise = saveHandler();

      if (notify) {
        savePromise
          .then(() => this.showAlertHandler('success'))
          .catch(() => this.showAlertHandler('warning'));
      }
    });
  }

  allToFalse = (undo = false) => {
    if (undo) {
      // try to recover state, if not saved yet
      clearTimeout(this.saveTimeout);
      window.subscriptions.discardHandlers[EMAIL_UNSUBSCRIBE_TITLE]();
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const sub of this.props.subscriptions) {
        sub.value = false;
        this.changeHandler(sub.key, false);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const subscription = nextProps.subscriptions.find((sub) => sub.key === this.subscription);

      if (this.state.isSubscribed !== subscription.value) {
        this.setState({ isSubscribed: subscription.value });
      }
    }
  }

  scheduleSave = () => new Promise((resolve, reject) => {
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      // we only update the toggles the user changed
      window.subscriptions.saveHandlers[EMAIL_UNSUBSCRIBE_TITLE]()
        .then(resolve)
        .catch(reject);
    }, SAVE_TIMEOUT);
  })

  render() {
    return (
      <section className="unsubscribe-section">
        <div className="container">
          {
            this.state.showAlert
              && <Alert hideCloseAction message={this.state.alertMessage} classes={this.state.alertCSSClass} onClick={this.hideAlertHandler} />
          }
          {
            this.state.isSubscribed
              ? (
                <PageTitleHeader
                  title="You've subscribed again!"
                  descriptionLarge={(
                    <a href="#" onClick={() => this.changeHandler(this.subscription, false)}>
                      Unsubscribe from
                      {this.friendly_subscription}
                    </a>
                  )}
                />
              )
              : (
                <PageTitleHeader
                  title="You've unsubscribed!"
                  descriptionLarge={(
                    <span>
                      You'll no longer receive emails from
                      {this.friendly_subscription}
                      .
                      <br />
                      Unsubscribed by accident?
                      <a href="#" onClick={() => this.changeHandler(this.subscription, true)}>Subscribe again</a>
                      .
                    </span>
                  )}
                />
              )
          }
          <SubscriptionList shouldUpdate description={<span>Manage your other email subscriptions</span>}>
            <PreferencesList userEmail={this.props.userEmail} changeHandler={this.changeHandler} subscriptions={this.props.subscriptions}>
              <li className="list-row in-card">
                <div className="row-content flex-repel right flex justify-center">
                  <button type="button" onClick={() => this.allToFalse(false)} className="button-regular">Disable All</button>
                </div>
              </li>
            </PreferencesList>
            <div className="preferences-consent-form">
              <p className="row-content subhead-medium in-card centered">
                By managing your email subscriptions here you explicitly accept Hole19
                <a className="link" href="/terms">Terms &amp; Conditions</a>
                {' '}
                and Privacy Policy
              </p>
            </div>
          </SubscriptionList>
        </div>
      </section>
    );
  }
}

function EmailUnsubscribeWithPreferences(props) {
  const Wrapped = withPreferences({
    title: EMAIL_UNSUBSCRIBE_TITLE,
    url: 'api/web/subscriptions/email',
    userEmail: props.email,
    api: props.pop_api_url,
    authToken: props.auth_token,
    toWrapped: {
      subscription: props.subscription,
      friendly_subscription: props.friendly_subscription,
    },
  })(EmailUnsubscribe);

  return (<Wrapped />);
}

export default EmailUnsubscribeWithPreferences;
