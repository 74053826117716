// Register last touch UTM params
const campaignParams = (query) => {
  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  const params = {};
  let index;
  let kw;
  for (index = 0; index < campaignKeywords.length; index += 1) {
    kw = query[campaignKeywords[index]];
    if (kw) {
      params[`${campaignKeywords[index]} [last touch]`] = kw;
    }
  }
  mixpanel.register(params);
};

if (typeof window === 'object') {
  window.mixpanelHelpers = { campaignParams };
}

export default campaignParams;
