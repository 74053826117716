import React from 'react';
import Toggle from '../Toggle.es6';
import Dropdown from '../Dropdown.es6';

class SubscriptionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { subState: props.value };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ subState: nextProps.value });
    }
  }

  updateSubscription = (value) => {
    this.setState({ subState: value }, () => this.props.changeHandler(this.props.subKey, value));
  };

  render() {
    const childProps = {
      clickHandler: this.updateSubscription,
      value: this.state.subState,
      simple: true,
    };

    return (
      <li className={`list-row in-card ${this.props.options ? 'to-break' : ''}`}>
        <div className="row-content flex-repel">
          <div className="left">
            <p className="body-bold">{this.props.title}</p>
            <p className="subhead-medium">{this.props.description}</p>
          </div>

          <div className="right">
            {this.props.options ? (
              <Dropdown {...childProps} options={this.props.options} />
            ) : (
              <Toggle {...childProps} />
            )}
          </div>
        </div>
      </li>
    );
  }
}

export default SubscriptionItem;
