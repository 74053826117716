export default {
  getImagePath: (courseImages, reqResolution = null) => {
    if (courseImages && courseImages.length) {
      let { resolutions } = courseImages[0];
      if (resolutions) {
        if (reqResolution) {
          reqResolution.split('.').forEach((l) => {
            resolutions = resolutions[l];
          });

          return resolutions;
        }
        return resolutions.small;
      }

      return courseImages[0].url;
    }

    return window.defaultCourseImages[Math.floor(Math.random() * window.defaultCourseImages.length)];
  },
};
