import React from 'react';
import FilterWhere from '../globalSearch/Where.es6';
import Item from './Course.es6';
import Loader from '../Loader.es6';

import DiscoverHelpers from '../../helpers/discoverCourses';
import apiHelpers from '../../helpers/api';

class CuratedListCourses extends React.Component {
  constructor(props) {
    super(props);

    this.pop = {
      token: props.token,
      api: props.pop_api_url,
    };

    this.state = { showNoResultsMessage: false };
  }

  componentWillMount() {
    window.defaultCourseImages = this.props.default_course_images;
  }

  componentDidMount() {
    apiHelpers.autoRefreshToken(() => this.pop.token, (data) => {
      apiHelpers.updateToken(this.pop, data);
    });
  }

  componentWillUnmount() {
    delete window.defaultCourseImages;
  }

  goToPlace = ({ externalRef } = {}) => {
    DiscoverHelpers.fetchPlaceByExternalRef(this.pop, externalRef)
      .then((response) => {
        this.setState({ showNoResultsMessage: false, showLoader: true }, () => {
          window.location.href = `/golf-courses/${response.slug}`;
        });
      })
      .catch(() => this.setState({ showNoResultsMessage: true }));
  }

  buildCourses = () => {
    const courseIdentities = this.props.curated_list.course_identities;
    return (
      courseIdentities.map((courseIdentity, index) => <Item key={index} {...courseIdentity} />)
    );
  }

  render() {
    const {
      place_name: location,
      place_slug: placeSlug,
      curated_list: curatedList,
    } = this.props;

    const { showLoader } = this.state;

    return (
      <section className="discover discover--curated-list">
        <section className="ho-hero-course ho-container">
          <a href={`/golf-courses/${placeSlug}`} title={`Golf Courses in ${location}`}>
            <i className="icn-left-arrow-circle" />
            <span className="subtitle">Back</span>
          </a>
          <h1 className="discover__title">
            <span>
              {curatedList.title}
              {Optional(curatedList.logo).map((logo) => <img key={curatedList.title} src={logo} alt={curatedList.title} />)}
            </span>
            in
            {' '}
            {location}
          </h1>
          <h2 className="subtitle">Browse through our 41.000+ golf courses. Find your next golf spot.</h2>
          <div className="global-search">
            <FilterWhere
              location={location}
              fetchHandler={this.goToPlace}
              pop={this.pop}
            />
          </div>
          {Optional(this.state.showNoResultsMessage).map(() => <p key="no-courses">No Golf Courses in this area.</p>)}
        </section>
        { showLoader
          ? <Loader />
          : (
            <section className="ho-section-courses">
              {this.buildCourses()}
            </section>
          )}
      </section>
    );
  }
}

export default CuratedListCourses;
