import React from 'react';
import moment from 'moment';
import Alert from '../../Alert.es6';

export default class ImportMultiplayerRound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: props.isVisible,
      showSuccessAlert: false,
      showErrorAlert: false,
    };

    this.importRound = this.importRound.bind(this);
  }

  succeed = () => {
    this.setState({
      isVisible: false,
      showSuccessAlert: true,
      showErrorAlert: false,
    });

    setTimeout(() => {
      this.setState({ showSuccessAlert: false });
    }, 2000);
  }

  fail() {
    this.setState({
      isVisible: true,
      showSuccessAlert: false,
      showErrorAlert: true,
    });

    setTimeout(() => {
      this.setState({ showErrorAlert: false });
    }, 2000);
  }

  importRound() {
    const params = {
      creation_token: this.props.creationToken,
      authenticity_token: this.props.authenticityToken,
      round_id: this.props.roundId,
    };

    Post('/performance/rounds/import_multiplayer_round', { body: JSON.stringify(params) })
      .then(() => this.succeed())
      .catch(() => this.fail());
  }

  render() {
    const date = moment(this.props.date).format('LLL');

    return (
      <div>
        {this.state.showSuccessAlert && <Alert message="Round successfully added!" classes="success" hideCloseAction />}
        {this.state.showErrorAlert && <Alert message="There was an error importing your round. Please try again later." classes="warning" hideCloseAction />}

        {this.state.isVisible
          && (
          <div className="banner import-multiplayer-round">
            <div className="container">
              <div className="text-container">
                <p className="title-5">DO YOU WANT TO ADD THIS ROUND TO YOUR PROFILE?</p>
                <p className="description">
                  This round was created by your buddy
                  {this.props.userName}
                  {' '}
                  on
                  {date}
                  .
                </p>
              </div>
              <div className="button-container">
                <button className="button-regular" type="button" onClick={this.importRound}>Add round</button>
              </div>
            </div>
          </div>
          )}
      </div>
    );
  }
}
