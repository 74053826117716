export default {
  autoRefreshToken: (authToken, callback) => {
    setInterval(() => {
      Get(`/api/web/keepalive?token=${authToken()}`).then(callback);
    }, 7 * 60 * 1000);
  },
  updateToken: (service, data) => {
    service.token = data.token;
  },
};
