import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import GenericModal from './shared/GenericModal.es6';
import NewLeaderboardForm from './shared/NewLeaderboardForm.es6';

function LeaderboardListEntry({ leaderboard, authenticityToken }) {
  return (
    <li className={`leaderboard-cell ${leaderboard.status}`}>
      <a className="cell-link" href={leaderboard.url}>
        <div className="event-info">
          <p className="event-date subhead text-light">
            {leaderboard.date}
            {Optional(leaderboard.status === 'live').map(() => <span className="label label-green">Live</span>)}
          </p>
          <p className="event-name title-6">{leaderboard.title || leaderboard.courseName}</p>
          <p className="event-course subhead">{leaderboard.courseName}</p>
        </div>

        <div className="event-mode">
          <div className="scoring-mode label">{leaderboard.scoringMode}</div>
        </div>

        <div className="event-owner flex-center-v">
          <div className="avatar avatar-32 round" style={{ backgroundImage: `url(${leaderboard.owner.avatar})` }} />
          <p className="owner-name body text-light">{leaderboard.owner.name}</p>
        </div>

        {Optional(!leaderboard.joined).map(() => (
          <div className="event-action">
            <form method="post" action={leaderboard.joinUrl}>
              <input type="hidden" name="_method" value="patch" />
              <input type="hidden" name="authenticity_token" value={authenticityToken} />
              <button type="submit" className="button-small icon-plus green">Join</button>
            </form>
          </div>
        ))}
      </a>
    </li>
  );
}

function LeaderboardList({ leaderboards, authenticityToken }) {
  return leaderboards.length > 0
    ? (
      <ol className="leaderboard-list">
        {leaderboards.map((leaderboard) => <LeaderboardListEntry leaderboard={leaderboard} authenticityToken={authenticityToken} />)}
      </ol>
    )
    : <p className="empty-message body-regular text-light text-center">You have no leaderboards yet.</p>;
}

class RoundLeaderboardsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalNewVisible: false,
      currentLeaderboardsList: 'all',
    };
  }

  parseLeaderboard = (data, defaultAvatar) => {
    const start = moment(data.started_at).toDate();
    const end = moment(data.ended_at).toDate();
    const now = moment().toDate();

    let status = 'live';
    if (now < start) {
      status = 'future';
    } else if (now > end) {
      status = 'past';
    }

    return {
      slug: data.slug,
      title: data.title,
      courseName: data.course.name,
      scoringMode: (data.scoring_mode || 'stroke_play').split('_').join(' '),
      date: moment(data.started_at).toDate().toDateString(),
      status,
      joined: data.joined,
      owner: {
        avatar: data.creator.avatar_url || defaultAvatar,
        name: data.creator.is_logged_user ? 'You' : data.creator.full_name,
      },
      url: `/performance/leaderboards/${data.slug}`,
      joinUrl: `/performance/leaderboards/${data.slug}/join`,
    };
  };

  showModalNew = (e) => {
    e.preventDefault();
    this.setState({ modalNewVisible: true });
    document.body.classList.add('no-scroll');
  };

  closeModalNew = (e) => {
    e.preventDefault();
    this.setState({ modalNewVisible: false });
    document.body.classList.remove('no-scroll');
  };

  showAllView = (e) => {
    e.preventDefault();
    this.setState({ currentLeaderboardsList: 'all' });
  }

  showLiveView = (e) => {
    e.preventDefault();
    this.setState({ currentLeaderboardsList: 'live' });
  }

  showFutureView = (e) => {
    e.preventDefault();
    this.setState({ currentLeaderboardsList: 'future' });
  }

  showPastView = (e) => {
    e.preventDefault();
    this.setState({ currentLeaderboardsList: 'past' });
  }

  organizeLeaderboards = (leaderboards) => {
    let leaderboardsToReturn = [];

    if (this.state.currentLeaderboardsList === 'all') {
      const futureLeaderboards = [];
      const liveLeaderboards = [];
      const pastLeaderboards = [];

      leaderboards.forEach((leaderboard) => {
        switch (leaderboard.status) {
          case 'live':
            liveLeaderboards.push(leaderboard);
            break;

          case 'future':
            futureLeaderboards.push(leaderboard);
            break;

          case 'past':
            pastLeaderboards.push(leaderboard);
            break;

          default:
            break;
        }
      });

      leaderboardsToReturn = [...liveLeaderboards, ...futureLeaderboards, ...pastLeaderboards];
    } else {
      leaderboards.forEach((leaderboard) => {
        if (leaderboard.status === this.state.currentLeaderboardsList) {
          leaderboardsToReturn.push(leaderboard);
        }
      });
    }

    return leaderboardsToReturn;
  }

  render() {
    const { defaultAvatar } = this.props.images;
    const leaderboards = this.organizeLeaderboards(this.props.data.leaderboards.map((l) => this.parseLeaderboard(l, defaultAvatar)));
    const authenticityToken = this.props.form_csrf_token;
    const popApi = this.props.pop_api;

    return (
      <main className="page-leaderboards">
        <GenericModal defaultModalStyles={false} visible={this.state.modalNewVisible} onCloseClick={this.closeModalNew} extraClass="modal-new-leaderboard">
          <NewLeaderboardForm authenticityToken={authenticityToken} popApi={popApi} />
        </GenericModal>

        <div className="bottom-bar">
          <a className="action button-regular" href="#" onClick={this.showModalNew}>Create New</a>
        </div>

        <header className="header-leaderboards">
          <div className="container">
            <div className="content">
              <h1 className="header-title title-2">Leaderboards</h1>

              <ul className="header-actions segmented">
                <li className="item">
                  <a href="#" className={`button-2 ${this.state.currentLeaderboardsList === 'all' ? 'current' : null}`} onClick={this.showAllView}>All</a>
                </li>

                <li className="item">
                  <a href="#" className={`button-2 ${this.state.currentLeaderboardsList === 'live' ? 'current' : null}`} onClick={this.showLiveView}>Live</a>
                </li>

                <li className="item">
                  <a href="#" className={`button-2 ${this.state.currentLeaderboardsList === 'future' ? 'current' : null}`} onClick={this.showFutureView}>Upcoming</a>
                </li>

                <li className="item">
                  <a href="#" className={`button-2 ${this.state.currentLeaderboardsList === 'past' ? 'current' : null}`} onClick={this.showPastView}>Past</a>
                </li>
              </ul>

              <a className="action-main button-small" href="#" onClick={this.showModalNew}>Create New</a>
            </div>

            <div className="events-header">
              <p className="caption-1-custom text-light">Event</p>
              <p className="caption-1-custom text-light">Mode</p>
              <p className="caption-1-custom text-light">Owner</p>
            </div>
          </div>
        </header>

        <section className="leaderboards">
          <div className="container">
            <LeaderboardList leaderboards={leaderboards} authenticityToken={authenticityToken} />
          </div>
        </section>
      </main>
    );
  }
}

RoundLeaderboardsIndex.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  leaderboards: PropTypes.arrayOf(PropTypes.object),
};

RoundLeaderboardsIndex.defaultProps = {
  leaderboards: [],
};

export default RoundLeaderboardsIndex;
