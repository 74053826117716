import React from 'react';
import CuratedSliderItem from './Course.es6';

class CuratedSlider extends React.Component {
  constructor(props) {
    super(props);

    this.slickSettings = {
      lazyLoad: 'ondemand',
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      draggable: false,
      nextArrow: '<i class="arrow-slider-right slick-next"></i>',
      prevArrow: '<i class="arrow-slider-left slick-prev"></i>',
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  }

  componentDidMount() {
    require('slick-carousel'); // eslint-disable-line global-require

    this.initSlider();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.courses !== this.props.courses;
  }

  initSlider() {
    $(this.slider).slick(this.slickSettings);
  }

  render() {
    const { courses } = this.props;

    return (
      <div className="slider" ref={(slider) => { this.slider = slider; }}>
        {courses.map((course, index) => <CuratedSliderItem key={index} {...course} />)}
      </div>
    );
  }
}

export default CuratedSlider;
