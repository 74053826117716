import React from 'react';

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  renderTitles = () => (
    <ul className="tabs__labels">
      {this.props.children.map(this.renderLabels)}
    </ul>
  );

  renderLabels = (child, index) => {
    const activeClass = (this.state.selected === index ? 'active' : '');

    return (
      <li key={index}>
        <a
          href="#"
          className={activeClass}
          onClick={(e) => this.handleClick(e, index)}
        >
          {child.props.label}
        </a>
      </li>
    );
  };

  renderContent = () => (
    <div className="tabs__content">
      {this.props.children[this.state.selected]}
    </div>
  );

  handleClick(e, index) {
    e.preventDefault();
    this.setState({ selected: index });
  }

  render() {
    return (
      <div className="tabs">
        {this.renderTitles()}
        {this.renderContent()}
      </div>
    );
  }
}

export default Tabs;
