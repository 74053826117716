import React from 'react';
import SubscriptionItem from './Item.es6';

function PreferencesList(props) {
  return (
    <ul className="preferences-list list-row-grouped">
      {
      props.subscriptions.map((subscription, index) => (
        <SubscriptionItem
          key={index}
          userEmail={props.userEmail}
          subKey={subscription.key}
          changeHandler={props.changeHandler}
          {...subscription}
        />
      ))
    }
      {props.children}
    </ul>
  );
}

export default PreferencesList;
