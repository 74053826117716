import React from 'react';

function GenericModal({
  visible, defaultModalStyles = true, scrollable, extraClass, onCloseClick, children, modalTitle, modalSubtitle,
}) {
  const containerClasses = ['modal', extraClass, (visible ? null : 'closed'), (scrollable ? 'scrollable' : null), (defaultModalStyles ? 'default-modal-styles' : '')].filter((obj) => obj).join(' ');
  const contentClasses = ['modal-card'].filter((obj) => obj).join(' ');

  return (
    <section className={containerClasses}>
      <div className="modal-bg" />
      <section className={contentClasses}>
        <a href="#" className="action-close" onClick={onCloseClick} />
        <div className="modal-content">
          {modalTitle ? <h2 className="modal-title title-5">{modalTitle}</h2> : null}
          {modalSubtitle ? <p className="modal-subtitle subhead text-center">{modalSubtitle}</p> : null}
          {children}
        </div>
      </section>
    </section>
  );
}

export default GenericModal;
