import React from 'react';
import Loader from '../../Loader.es6';
import LeaderboardExpanded from './LeaderboardExpanded.es6';

import CourseProfileHelpers from '../../../helpers/courseProfile';

export default class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.defaultAvatar = props.defaultAvatar;
    this.state = { viewExpanded: false };
    this.changeExpandedState = this.changeExpandedState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.publicId !== this.props.course.publicId) {
      this.updateLeaderboard();
    }
  }

  componentDidMount() {
    this.updateLeaderboard();
  }

  updateLeaderboard(coursePublicId = this.props.course.publicId) {
    this.setState({ leaderboard: undefined }, () => {
      CourseProfileHelpers.fetchLeaderboard(this.props.service, coursePublicId)
        .then((json) => this.setState({ leaderboard: json.leaderboards.gross }));
    });
  }

  changeExpandedState() {
    this.setState((prevState) => ({ viewExpanded: !prevState.viewExpanded }));
    this.hideBodyScroll(this.state.viewExpanded);
  }

  hideBodyScroll(state) {
    (function () {
      document.body.style.overflow = (state) ? 'auto' : 'hidden';
    }());
  }

  render() {
    const players = this.state.leaderboard;

    return (
      <div className="course-content-leaderboard">
        {Optional(this.state.viewExpanded).map(() => <LeaderboardExpanded key="expanded-lb" leaderboard={this.state.leaderboard} course={this.props.course} unmountMe={this.changeExpandedState} defaultAvatar={this.defaultAvatar} />)}

        <div className="section-title">
          <h3 className="caption-1-custom text-light">Leaderboard</h3>
          {Optional(players && players.length > 5).map(() => <a key="view-all" onClick={this.changeExpandedState} className="caption-1-bold">View All</a>)}
        </div>

        {Optional(players === undefined).map(() => <div key="lb-loader" className="content-loading"><Loader /></div>)}
        {Optional(players && players.length === 0).map(() => <p key="ldb-not-available" className="body-regular-secondary content-not-available">Leaderboard not available for this course.</p>)}

        {Optional(players).map((plrs) => (
          <ol key="ppl-lb-list" className="leaderboard">
            {plrs.slice(0, 5).map((rank) => (
              <li key={rank.rank}>
                <span className="leaderboard-rank">{rank.rank}</span>
                <img className="leaderboard-avatar avatar-40" role="presentation" src={rank.user.avatar_url ? rank.user.avatar.thumb.small : this.defaultAvatar} alt={rank.user.full_name} />
                <p className="leaderboard-name body-heavy">{rank.user.full_name}</p>
                <p className="leaderboard-score headline text-light">{rank.score}</p>
              </li>
            ))}
          </ol>
        ))}
      </div>
    );
  }
}
