import React from 'react';

class SubscriptionList extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.title !== this.props.title || (nextProps.shouldUpdate === true);
  }

  render() {
    return (
      <section className="preferences-section">
        <div className="container-6">
          <header className="section-header flex-center-h">
            <h4 className="section-title title-2">{this.props.title}</h4>
            <p className="section-description body-medium">{this.props.description}</p>
          </header>
          {this.props.children}
        </div>
      </section>
    );
  }
}

export default SubscriptionList;
