import React from 'react';
import PopApi from './PopApi.es6';
import Loader from '../../../Loader.es6';

function CourseIdentityList({ key, courseIdentities, onCourseSelect }) {
  return (
    <ol className="course-suggestions" key={`course-list-${key}`}>
      {courseIdentities.map((course, index) => (
        <li className="suggestion" key={`course-${index}`}>
          <a href="#" className="body text-light" onClick={(e) => onCourseSelect(e, course)}>
            {course.name}
            ,
            {' '}
            {course.location}
          </a>
        </li>
      ))}
    </ol>
  );
}

export default class CourseSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetcher = PopApi(this.props.pop_api);
  }

  cancelRequests = () => {
    if (this.courseIdentityRequest) { this.courseIdentityRequest.cancel(); }
  }

  onCourseIdentitySearchChange = (e) => {
    const query = e.target.value.trim();

    this.cancelRequests();

    const newState = { ...this.state };
    delete newState.courseIdentities;
    delete newState.query;

    if (query.length >= 3) {
      newState.query = query;
      this.setState(newState);
    } else {
      this.setState(newState);
      return;
    }

    this.courseIdentityRequest = this.fetcher.searchCourseIdentities(query);
    this.courseIdentityRequest.request.then((data) => {
      if (data) {
        this.setState({ courseIdentities: data.course_identities });
      }
    });
  }

  onCourseIdentitySelect = (e, courseIdentity) => {
    e.preventDefault();
    this.cancelRequests();

    const newState = { ...this.state, courseIdentity };
    delete newState.courseIdentities;
    delete newState.query;
    this.setState(newState);

    this.courseIdentityRequest = this.fetcher.getCourseIdentity(courseIdentity.region_slug, courseIdentity.slug);
    this.courseIdentityRequest.request.then((data) => {
      if (data) {
        this.setState({ courseIdentity: data, currentCoursePublicId: data.courses[0].public_id });
      }
    });
  }

  clearSelectedCourseIdentity = (e) => {
    e.preventDefault();
    this.cancelRequests();

    const newState = { ...this.state };
    delete newState.courseIdentity;
    this.setState(newState);
  }

  render() {
    if (this.state.courseIdentity) {
      return (
        <div className="input-group multiple">
          <div className="input-subgroup group-course">
            <label className="input-label">Course</label>
            <input type="hidden" name="course_public_id" value={this.state.currentCoursePublicId} required />
            <span className="course-name">
              <input type="text" className="text" value={this.state.courseIdentity.name} disabled />
              <button type="button" onClick={this.clearSelectedCourseIdentity} className="clear-input" />
            </span>
          </div>
          {this.state.courseIdentity.courses
            ? (
              <div className="input-subgroup input-select">
                <label className="input-label">Variation</label>
                <select style={{ width: '100%' }} value={this.state.currentCoursePublicId} onChange={(e) => this.setState((prevState) => ({ ...prevState, currentCoursePublicId: e.target.value }))}>
                  {
                this.state.courseIdentity.courses.map((course, index) => {
                  let holes = course.holes_count;
                  holes += ` ${Math.abs(course.holes_count) > 1 ? 'holes' : 'hole'}`;
                  return (
                    <option key={index} value={course.public_id}>
                      {course.name}
                      ,
                      {holes}
                    </option>
                  );
                })
              }
                </select>
              </div>
            )
            : <Loader />}
        </div>
      );
    }

    return (
      <courseSelect>
        <div className="input-group">
          <label className="input-label">Course</label>
          <input type="search" placeholder="Find a course" onChange={this.onCourseIdentitySearchChange} required />
          <input type="text" name="do-not-allow-submit" hidden required />
        </div>

        {Optional(this.state.query && !this.state.courseIdentities).map(() => (
          <Loader />
        ))}

        {Optional(this.state.courseIdentities).map(() => (
          <CourseIdentityList key={this.state.query} courseIdentities={this.state.courseIdentities} onCourseSelect={this.onCourseIdentitySelect} />
        ))}
      </courseSelect>
    );
  }
}
